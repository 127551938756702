import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-release-info-dialog',
  templateUrl: './release-info-dialog.component.html',
  styleUrls: ['./release-info-dialog.component.css']
})
export class ReleaseInfoDialogComponent implements OnInit {

  data: any;
  release: any;

  constructor(private dialogRef: MatDialogRef<ReleaseInfoDialogComponent>,
              @Inject(MAT_DIALOG_DATA) data: any) { 

                this.data = data.info;
                this.release = data.release;
              }

  ngOnInit() {

  }

}
