<h1 style="text-decoration:underline;">Release: {{this.release.replace('.zip', '')}}</h1>
<table>
    <tr>
      <th>Name</th>
      <th>Version</th>
      <th>Created</th>
      <th>Size (MB)</th>
      <th>Source Branch</th>
    </tr>
    <tr *ngFor = "let img of this.data">
      <td>{{img.name.split("-")[0]}}</td>
      <td>{{img.name.split("-")[1]}}</td>
      <td>{{img.created.split("T")[0]}}</td>
      <td>{{img.size / 1024 / 1024 | number : '1.2-2'}} MB</td>
      <td>{{img.sourceBranchName}}</td>
    </tr>
</table>
