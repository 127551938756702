import { Component, Inject, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Release } from '../release-model';
import { ReleaseService } from '../release.service';
import { NotifierService } from 'angular-notifier';
import { HttpClient } from '@angular/common/http';
import { ReleaseInfoModel } from '../release-info-model';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ReleaseInfoDialogComponent } from '../release-info-dialog/release-info-dialog.component';

@Component({
  selector: 'app-releases',
  templateUrl: './releases.component.html',
  styleUrls: ['./releases.component.css']
})
export class ReleasesComponent implements OnInit {

  private notifier: NotifierService;

  displayedColumns: string[] = ['Name', 'CreatedOn', 'Size', 'Action'];

  releases: Release[] = [];

  dataSource: any

  isLoading = true;

  releaseInfo: ReleaseInfoModel[] = []

  constructor(public releaseService: ReleaseService,
    public notifierService: NotifierService,
    public httpClient: HttpClient,
    private dialog: MatDialog) {

    this.notifier = notifierService;

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnInit(): void {

    this.getReleases();
  }

  refresh() {
    this.getReleases();
  }

  getReleases() {
    this.isLoading = true;
    this.releaseService.getReleases()
      .subscribe(
        (data) => {
          this.releases = data
          this.dataSource = new MatTableDataSource(this.releases);
          this.isLoading = false;
        },
        (error) => {
          console.log(`Error: ${error}`)
          this.notifier.notify('error', error.message);
          this.isLoading = false;
        }
      )
  }

  getReleaseInfo(releaseId: string) {
    this.releaseService.getReleaseInfo(releaseId.split('.')[3])
      .subscribe(
        (data) => {
          
          if(data == null){
            this.notifier.notify('error', 'Faield to get release info');  
            return;
          }

          this.releaseInfo = data;
          
          const dialogConfig = new MatDialogConfig();
          
          dialogConfig.autoFocus = true;
          
          dialogConfig.data = {
            info: this.releaseInfo,
            release: releaseId
          }
          this.dialog.open(ReleaseInfoDialogComponent, dialogConfig);
          
          console.log(this.releaseInfo)

        },
        (error) => {
          console.log(`Error: ${error}`)
          this.notifier.notify('error', error.message);
        }
      )
  }
  roundTo(num: number, places: number) {
    const factor = 10 ** places;
    return Math.round(num * factor) / factor;
  };

  download(name: string, uri: string) {

    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', uri);
    debugger
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    link.remove();

  }

}
