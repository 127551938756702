
<mat-toolbar style="background-color: #0e294a;">
  <img src="https://www.polytex-lm.com/assets/images/logo.png" alt="">
  
  <div class="toolbar-spacer"></div>

  <button mat-raised-button *ngIf="!loginDisplay" (click)="login()">
    Login
  </button>

  <div *ngIf="loginDisplay">
    <app-notification></app-notification>
    <button mat-icon-button class="bg-color" [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item>
        <mat-icon>account_circle</mat-icon>
        {{this.authService.instance.getAllAccounts()[0].name}}
      </button>
      <button mat-menu-item (click)="logout()">
        <mat-icon>power_settings_new</mat-icon>
        Log Out
      </button>
    </mat-menu>
  </div>
</mat-toolbar>
<div class="container">
  <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
  <router-outlet *ngIf="!isIframe"></router-outlet>
</div>

<!-- 
<footer>
  <mat-toolbar>
    <div>
    </div>
  </mat-toolbar>
</footer> -->