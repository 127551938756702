<button  class="bg-color" mat-icon-button [matMenuTriggerFor]="menuAlert">
    <mat-icon [matBadgeHidden]="this.runningReleases.length == 0"  matBadgeColor="accent" style="color: white;" [matBadge]="this.runningReleases.length" matBadgePosition="before">notifications</mat-icon>
</button>
  <mat-menu #menuAlert="matMenu">
    <div *ngIf="this.runningReleases.length > 0" mat-menu-item>
      <div *ngFor="let item of this.runningReleases; let i = index">
        <button mat-button>
          <a>
          {{i+1}}. {{item}} in progress
          </a>
          <mat-icon><mat-spinner diameter="20">
          </mat-spinner></mat-icon>
          <mat-divider></mat-divider>
        </button>
      </div>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div *ngIf="this.runningReleases.length == 0">
      <mat-label>No new notifications from this session</mat-label>
      <mat-divider></mat-divider>
    </div>
  </mat-menu>