<notifier-container></notifier-container>
<div *ngIf="!loginDisplay" style="text-align: center; margin-top: 10%;">
  <h1>PM8 Releases</h1>
  <p>Please login to see PM8 versions</p>
  <div>
    <img style="width: 20%;" src="../../assets/authentication.svg">
  </div>
</div>

<app-releases *ngIf="loginDisplay"></app-releases>
