import { Component, OnInit } from '@angular/core';
import { HubConnectionBuilder } from '@microsoft/signalr';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {

  connection: any;
  messages: any;
  userName: any;
  hideJoin: boolean | undefined;
  runningReleases: any = [];
  constructor() { }

  ngOnInit(): void {
    
    this.initWebSocket();
    this.connection.start();
  }

  initWebSocket() {
    this.connection = new HubConnectionBuilder()
      .withUrl('https://download-pm8-api.azurewebsites.net/hub/release')
      .build();

    this.connection.on('RunningRelease', (releases: string) => {
      console.log(releases);
      
      this.runningReleases = releases;

      this.messages.push({ releases });
    });

    this.connection.on('userJoined', (user: string) => {
      if (user === this.userName) {
        this.hideJoin = true;
      }
      this.messages.push({ from: '> ', body: user + ' joined' });
    });

    this.connection.on('userLeft', (user: string) => {
      this.messages.push({ from: '! ', body: user + ' has left!' });
    });
  }

}
