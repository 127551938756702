import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Release } from './release-model';

import { protectedResources } from './auth-config';
import { ReleaseInfoModel } from './release-info-model';

@Injectable({
  providedIn: 'root'
})
export class ReleaseService {
  
  url = protectedResources.Pm8ReleaseApi.endpoint;

  constructor(private http: HttpClient) { }

  getReleases() { 
    return this.http.get<Release[]>(this.url)
  }


  getReleaseInfo(releaseId: string) { 
    return this.http.get<ReleaseInfoModel[]>(`${this.url}/${releaseId}`)
  }
}
