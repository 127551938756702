<div class="wrapper" *ngIf="this.isLoading == true">
  <mat-spinner class="inner"></mat-spinner>
</div>

<div style="text-align: center;" *ngIf="this.releases.length == 0 && isLoading == false">
  <h2>No Releases Found</h2>
  <img style="width: 20%;" src="../../assets/no_data.svg">
</div>


<div *ngIf="this.releases.length > 0 && isLoading == false">
  <div>
    <mat-form-field appearance="standard">
      <mat-label>Search</mat-label>
      <input matInput (keyup)="applyFilter($event)" #input>
    </mat-form-field>

  </div>
 
  <div>
    <div style="text-align: right;">
      <button mat-fab style="position:relative; top: 20px; right: 4%;" (click)="refresh()">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

      <!-- Name Column -->
      <ng-container matColumnDef="Name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let element">
          {{element.name.replace('.zip', '')}}
        </td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="CreatedOn">
        <th mat-header-cell *matHeaderCellDef> Release Date </th>
        <td mat-cell *matCellDef="let element"> {{element.createdOn}} </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="Size">
        <th mat-header-cell *matHeaderCellDef> Size</th>
        <td mat-cell *matCellDef="let element"> {{element.contentLength / 1024/ 1024 / 1024 | number : '1.2-2' }} GB
        </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="Action">
        <th mat-header-cell *matHeaderCellDef> Action</th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="getReleaseInfo(element.name)">
              <mat-icon>info</mat-icon>
              Info
            </button>
            <button mat-menu-item (click)="download(element.name, element.uri)">
              <mat-icon>download</mat-icon>
              Download
            </button>
          </mat-menu>
        </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
      </tr>
    </table>

  </div>

</div>

